import React from 'react';
import { FaUserFriends, FaClock } from 'react-icons/fa';

import { ASSETS_URL } from '../../constants';
import { IRecipe } from 'types/directus';

import styles from './RecipeCard.module.scss';

interface IRecipeCardProps {
  recipe: IRecipe;
}

const RecipeCard: React.FC<IRecipeCardProps> = ({ recipe }) => {
  return (
    <li className={styles['recipe-card']}>
      <a href={`/recipes/${recipe.id}`}>
        <div
          className={styles['recipe-card__image']}
          style={{ backgroundImage: `url(${ASSETS_URL + recipe.feature_image})` }}
        >
          {/* <img src={ASSETS_URL + recipe.feature_image} alt={recipe.name} /> */}
        </div>

        <div className={styles['recipe-card__container']}>
          <h3 className={styles['recipe-card__title']}>{recipe.name}</h3>
          <span className={styles['recipe-card__elem']}>{recipe.category?.name}</span>
          {recipe.serves &&
            <span className={styles['recipe-card__elem']}><FaUserFriends />{recipe.serves}</span>
          }
          {recipe.duration &&
            <span className={styles['recipe-card__elem']}><FaClock />{recipe.duration}</span>
          }
          {/*recipe.difficulty &&
            <span className={styles['recipe-card__elem']}>{recipe.difficulty}</span>
          */}
        </div>
      </a>
    </li>
  );
};

export default RecipeCard;
