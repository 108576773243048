import React from 'react';

import { useServiceOnLoad } from 'hooks';
import { IProductCategory, IRecipe, IProduct, IStore } from 'types/directus';
import { getProductCategories, getRecipes, getProducts, getStores } from 'services/directusService';

interface IContentContext {
  productCategories: Array<IProductCategory> | null;
  recipes: Array<IRecipe> | null;
  products: Array<IProduct> | null;
  stores: Array<IStore> | null;
  isLoading: boolean;
};

export const ContentContext = React.createContext<IContentContext>({} as IContentContext);

/**
 * ContentContext provider.
 *
 * @description Provides access to CMS data.
 * @param {React.ReactNode} children Child components.
 * @returns {React.Element} Returns react component.
 */
export const ContentContextProvider: React.FC = ({ children }) => {
  const { response: productCategories, isLoading: isLoadingProductCategories } = useServiceOnLoad(getProductCategories);
  const { response: recipes, isLoading: isLoadingRecipes } = useServiceOnLoad(getRecipes);
  const { response: products, isLoading: isLoadingProducts } = useServiceOnLoad(getProducts);
  const { response: stores, isLoading: isLoadingStores } = useServiceOnLoad(getStores);

  return (
    <ContentContext.Provider value={{
      productCategories,
      recipes,
      products,
      stores,
      isLoading: isLoadingProductCategories || isLoadingRecipes || isLoadingProducts || isLoadingStores
    }}>
      {children}
    </ContentContext.Provider>
  );
};
