import React from 'react';

import arrowRightImg from 'assets/images/icons/arrow-right.svg';

import styles from './HomeHeader.module.scss';
import { NavigationBar } from 'components';

const HomeHeader: React.FC = () => {
  return (
    <>
      <NavigationBar />

      <header>
        <div style={{maxWidth: '1400px', margin: 'auto'}}>
          <div className={styles['header-container']}>
            <h2 className={styles['header-container__subheading']}>Evergreen Foods</h2>
            <h1 className={styles['header-container__heading']}>Australia&apos;s favourite producer of delicious tofu, soy, noodle and dumpling products.</h1>

            <div className='button-group'>
              <a className='button button--primary' href='/products'>View all Products</a>
              <a className='button' href='/contact'>Find a Store</a>
            </div>
          </div>

          <a className='button button--primary button--small' href='#categories'><img src={arrowRightImg} alt='Scroll to content' /></a>
        </div>
      </header>
    </>
  );
};

export default HomeHeader;
