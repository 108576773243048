import React from 'react';

import { IProduct } from 'types/directus';
import { ASSETS_URL } from '../../constants';

import styles from './NutritionModal.module.scss';

interface INutritionModalProps {
  product?: IProduct;
  show: boolean;
  closeModal: () => void;
}

const NutritionModal: React.FC<INutritionModalProps> = ({ show, product, closeModal }) => {
  if (!show) {
    return null;
  }

  return (
    <div className={styles['nutrition-modal']}>
      <div className={styles['nutrition-modal__container']}>
        <img src={ASSETS_URL + product?.nutrition_image} />
        <button
          className={styles['nutrition-modal__close']}
          onClick={closeModal}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default NutritionModal;
