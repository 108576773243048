import { useEffect, useState, useCallback } from 'react';

interface IServiceResponse<TResult> {
  isLoading: boolean;
  response: TResult | null;
  error: any | null;
  refresh: () => void;
}

// Invokes a service on mount and exposes relevant props
const useServiceOnLoad = <TResult>(service: () => Promise<TResult>): IServiceResponse<TResult> => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [response, setResponse] = useState<TResult | null>(null);
  const [error, setError] = useState<any | null>(null);

  const makeRequest = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await service();
      setResponse(response);
    }
    catch (e) {
      setError(e);
    }
    finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    // Make request on first load
    makeRequest();
  }, []);

  return {
    isLoading,
    response,
    error,

    // Expose function to refresh request
    refresh: makeRequest
  };
};

export default useServiceOnLoad;