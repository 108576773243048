import React, { useState, useContext, useMemo } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';

import { ASSETS_URL } from '../../constants';
import { ContentContext } from 'contexts/ContentContext';
import { IProduct } from 'types/directus';

import {
  NavigationBar,
  Section,
  Footer,
  StoreLocator,
  NutritionModal,
  Spinner
} from 'components';

import noodlesImg from 'assets/images/cooking-08.png';
import arrowLeftImg from 'assets/images/icons/arrow-left.svg';

import styles from './ProductDetails.module.scss';

const ProductDetails: React.FC = () => {
  const { productId } = useParams();
  const { products, isLoading } = useContext(ContentContext);
  const [modalProduct, setModalProduct] = useState<IProduct | null>(null);

  const selectedProduct = useMemo(() => products?.find(product => `${product.id}` === productId), [products, productId]);

  // Filter recipes to only show ones in same category
  const relatedProducts = useMemo(() => products?.filter(
    product => product.category?.id === selectedProduct?.category?.id), [products, productId
  ]);

  const isMobile = useMediaQuery({ query: '(max-width: 764px)' });

  if (isLoading) {
    return (
      <Spinner />
    );
  }

  if (!selectedProduct) {
    return (
      <Navigate to='/products' />
    );
  }

  return (
    <>
      <NavigationBar />

      <Section className={`${styles['header']} content-bg content-bg--top`}>
        <a href='/products' className='back-button'><img src={arrowLeftImg} />Go back</a>

        <div className='display--flex-wrap'>
          <div className='halfWidth'>
            <h1>{selectedProduct.name}</h1>
            {
              selectedProduct.description &&
                <p className='text-small'>
                  {selectedProduct.description}
                </p>
            }

            <div className='button-group'>
              {
                selectedProduct.nutrition_image &&
                  <button className='button--primary' onClick={() => setModalProduct(selectedProduct)}>
                    Nutritional information
                  </button>
              }
              <a className='button' href='/wholesales'>Wholesales</a>
            </div>
          </div>

          <div className={`${styles['header__image']} halfWidth display--flex`}>
            <img src={ASSETS_URL + selectedProduct.feature_image} alt={selectedProduct.name} />
          </div>
        </div>
      </Section>

      {selectedProduct.feature_recipe &&
        <Section className={styles['recipe']}>
          <div className='halfWidth' style={{marginLeft: 'auto'}}>
            <h2 className={styles['recipe__head']}>Featured recipe</h2>
            <h3 className={styles['recipe__subhead']}>{selectedProduct.feature_recipe.name}</h3>
            <p>{selectedProduct.feature_recipe.description}</p>

            <a className='button button--light' href={`/recipes/${selectedProduct.feature_recipe.id}`}>Learn More</a>
          </div>

          <img className={styles['recipe__image']} src={noodlesImg} alt='Featured recipe' />
        </Section>
      }

      {
        relatedProducts && relatedProducts.length &&
          <Section className={styles['related']} style={selectedProduct.feature_recipe && {marginTop: '480px'}}>
            <h2>Related Products</h2>
            <ul className={styles['related__related-items']}>
              {relatedProducts.map(product => (
                <li key={product.id}>
                  <a href={`/products/${product.id}`} className={styles['related-item__link']}>
                    <img src={ASSETS_URL + product.feature_image} alt={product.name} />
                  </a>
                  <div className={styles['related-item__content']}>
                    {product.name}
                    {
                      product.nutrition_image &&
                        <button className={styles['related-item__nutrition']} onClick={() => setModalProduct(product)}>
                          <FaPlus />Nutritional Information
                        </button>
                    }
                  </div>
                </li>
              ))}
            </ul>
          </Section>
      }

      <Section className={styles['locator']}>
        <StoreLocator
          style={{
            position: 'absolute',
            transform: 'translateX(-50%)',
            left: '50%',
            bottom: isMobile ? '10%' : '30%'
          }} />
      </Section>

      <Footer />

      <NutritionModal
        show={!!modalProduct}
        closeModal={() => setModalProduct(null)}
        product={selectedProduct} />
    </>
  );
};

export default ProductDetails;
