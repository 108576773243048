import React, { useContext, useMemo } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { FaPlay, FaClock, FaUserFriends } from 'react-icons/fa';

import { ASSETS_URL } from '../../constants';
import { ContentContext } from 'contexts/ContentContext';

import {
  NavigationBar,
  Section,
  Footer,
  RecipeList,
  Spinner
} from 'components';

import arrowLeftImg from 'assets/images/icons/arrow-left.svg';

import styles from './RecipeDetails.module.scss';

const RecipeDetails: React.FC = () => {
  const { recipeId } = useParams();
  const { recipes, isLoading } = useContext(ContentContext);

  const selectedRecipe = useMemo(() => recipes?.find(recipe => recipe.id == recipeId), [recipes, recipeId]);

  // Filter recipes to only show ones in same category
  const relatedRecipes = useMemo(() =>
    recipes?.filter(recipe => recipe.category?.id == selectedRecipe?.category?.id), [recipes, recipeId]
  );

  if (isLoading) {
    return (
      <Spinner />
    );
  }

  if (!selectedRecipe) {
    return (
      <Navigate to='/recipes' />
    );
  }

  return (
    <>
      <NavigationBar />

      <div className={`${styles['content-container']} content-bg content-bg--top`}>
        <div className={styles['content-container__inner']}>
          <a href='/recipes' className='back-button'><img src={arrowLeftImg} />Go back</a>

          <Section className={styles['card']}>
            <div className={styles['card__container']}>
              <div className={styles['card__column']}>
                <h1 className={styles['card__header']}>{selectedRecipe.name}</h1>
                <p>{selectedRecipe.description}</p>
              </div>

              <div
                className={styles['card__feature-image']}
                style={{ backgroundImage: `url(${ASSETS_URL + selectedRecipe.feature_image})` }}
              />
              {/* <img
                className={styles['card__feature-image']}
                src={ASSETS_URL + selectedRecipe.feature_image}
                alt={selectedRecipe.name} /> */}
            </div>

            <div className={styles['card__container']}>
              <span className={styles['card__element']}>{selectedRecipe.category?.name}</span>

              {selectedRecipe.serves &&
                <span className={styles['card__element']}><FaUserFriends />{selectedRecipe.serves}</span>
              }
              {selectedRecipe.duration &&
                <span className={styles['card__element']}><FaClock />{selectedRecipe.duration}</span>
              }
              {selectedRecipe.difficulty &&
                <span className={styles['card__element']}>{selectedRecipe.difficulty}</span>
              }
              {selectedRecipe.video_url &&
                <span className={styles['card__element']} style={{marginLeft: 'auto'}}><FaPlay /><a href={selectedRecipe.video_url} target='_blank' rel='noreferrer'>Watch video</a></span>
              }
            </div>
          </Section>

          <Section className={styles['details']} containerClass={styles['details__container']}>
            <div className='col--auto'>
              <h2>Ingredients</h2>

              <div
                className={styles['details__ingredients']}
                dangerouslySetInnerHTML={{__html: selectedRecipe.ingredients || ''}} />

              {/* <ul className={styles['details__ingredients']}>
                {selectedRecipe?.recipe_ingredients?.map(ingredient => (
                  <li key={ingredient.id}>{ingredient.name}</li>
                ))}
              </ul> */}

            </div>

            <div className='col--auto'>
              <h2>Method</h2>
              <div dangerouslySetInnerHTML={{__html: selectedRecipe.method || ''}} />
            </div>
          </Section>

          <RecipeList recipes={relatedRecipes || []} />
        </div>
      </div>

      <Footer />
    </>
  );
};

export default RecipeDetails;
