import React from 'react';

import styles from './Spinner.module.scss';

const Spinner: React.FC = () => {
  return (
    <div className={`${styles['loader-container']} content-bg content-bg--top`}>
      <div className={styles['loader']}><div /><div /><div /><div /></div>
      <div className='text--center'>Loading...</div>
    </div>
  );
};

export default Spinner;
