import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import logoImg from 'assets/images/logo-w.png';
import styles from './NavigationBar.module.scss';

const NavigationBar: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  return (
    <nav>
      <ul className={`${styles['nav__list']} ${!menuOpen ? 'closed' : ''}`}>
        <li>
          <NavLink to='/'>Home</NavLink>
        </li>
        <li>
          <NavLink to='/products'>Product Range</NavLink>
        </li>
        <li>
          <NavLink to='/recipes'>Recipes</NavLink>
        </li>
        <li className={styles['nav__image']}>
          <NavLink to='/'><img src={logoImg} alt='Evergreen' /></NavLink>
        </li>
        <li>
          <NavLink to='/about'>Our Mission</NavLink>
        </li>
        <li>
          <NavLink to='/contact'>Contact</NavLink>
        </li>
        <li>
          <NavLink to='/wholesales'>Wholesales</NavLink>
        </li>
      </ul>

      <button className={`${styles['nav__button']}  ${menuOpen ? styles['nav__button--opened'] : ''}`} onClick={() => setMenuOpen(!menuOpen)}>
        <div className={styles['nav__button-container']}>
          <span></span>
        </div>
        {/*<svg viewBox='0 0 100 60' width='40' height='40' fill='white'>
          <rect width='100' height='10' />
          <rect y='30' width='100' height='10' />
          <rect y='60' width='100' height='10' />
        </svg>*/}
      </button>
    </nav>
  );
};

export default NavigationBar;
