import React, { useState } from 'react';

import { RecipeCard } from 'components';
import { IRecipe } from 'types/directus';

import arrowLeftImg from 'assets/images/icons/arrow-left.svg';
import arrowRightImg from 'assets/images/icons/arrow-right.svg';
import styles from './RecipeList.module.scss';

interface IRecipeListProps {
  recipes: Array<IRecipe> | null;
}

const RecipeList: React.FC<IRecipeListProps> = ({ recipes }) => {
  const [recipeOffset, setRecipeOffset] = useState<number>(0);

  const cardWidth = 260;

  const updateOffset = (direction: 'left' | 'right') => {
    if (!recipes) {
      return;
    }

    switch (direction) {
      case 'right':
      {
        if (recipeOffset > -(cardWidth * (recipes.length - 1))) {
          setRecipeOffset(recipeOffset - cardWidth);
        }
        break;
      }

      case 'left':
      {
        if (recipeOffset < 0) {
          setRecipeOffset(recipeOffset + cardWidth);
        }
        break;
      }
    }
  };

  return (
    <>
      <div className={styles['recipes__title']}>
        <h2 className={styles['recipes__heading']}>Healthy recipes</h2>

        <div className='button-group text--center'>
          <button className='button--small' onClick={() => updateOffset('left')}><img src={arrowLeftImg} /></button>
          <button className='button--small' onClick={() => updateOffset('right')}><img src={arrowRightImg} /></button>
        </div>
      </div>

      <ul className={styles['recipes__list']} style={{marginLeft: `${recipeOffset}px`}}>
        {recipes?.map(item => (
          <RecipeCard key={item.id} recipe={item} />
        ))}
      </ul>
    </>
  );
};

export default RecipeList;
