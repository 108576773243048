import React, { useContext, useState } from 'react';

import { ContentContext } from 'contexts/ContentContext';

import {
  NavigationBar,
  Section,
  Footer,
  RecipeCard,
  Spinner
} from 'components';

import styles from './Recipes.module.scss';

const Recipes: React.FC = () => {
  const { productCategories, recipes, isLoading } = useContext(ContentContext);

  const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(null);

  let filteredRecipes = [...recipes || []];

  // Filter recipes if a category is selected
  if (selectedCategoryId !== null) {
    filteredRecipes = filteredRecipes.filter(recipe => recipe.category?.id === selectedCategoryId);
  }

  if (isLoading) {
    return (
      <Spinner />
    );
  }

  return (
    <>
      <NavigationBar />

      <div className='content-bg content-bg--top'>
        <Section>
          <h1>Healthy recipes</h1>

          <div className='display--flex-wrap'>
            <aside className={styles['categories']}>
              <h2 className={styles['categories__heading']}>Categories</h2>

              <ul>
                <li>
                  <button
                    className={selectedCategoryId === null ? `${styles['categories__button']} ${styles['categories__button--selected']}` : styles['categories__button']}
                    onClick={() => setSelectedCategoryId(null)}>
                    All recipes
                  </button>
                </li>
                {productCategories?.map(item => (
                  <li key={item.id}>
                    <button
                      className={selectedCategoryId === item.id ? `${styles['categories__button']} ${styles['categories__button--selected']}` : styles['categories__button']}
                      onClick={() => setSelectedCategoryId(item.id)}>
                      {item.name === 'Noodles' ? 'Noodle' : item.name} recipes
                    </button>
                  </li>
                ))}
              </ul>
            </aside>


            {filteredRecipes?.length > 0
              ? <ul className={styles['recipes-list']}>
                {filteredRecipes?.map(item => (
                  <RecipeCard key={item.id} recipe={item} />
                ))}
              </ul>
              : <div className={styles['error-container']}>
                <p className={styles['error-text']}>No matching recipes were found.</p>
              </div>
            }
          </div>
        </Section>
      </div>

      <Footer />
    </>
  );
};

export default Recipes;
