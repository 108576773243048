import React from 'react';

import {
  NavigationBar,
  Section,
  Footer,
  WholesalerForm
} from 'components';

import cookingImg from 'assets/images/cooking-01.png';
import styles from './Wholesalers.module.scss';

const Wholesalers: React.FC = () => {
  return (
    <>
      <NavigationBar />

      <Section className={`${styles['header']} content-bg content-bg--top`}>
        <div className='display--flex-wrap'>
          <div className='halfWidth'>
            <h1>Australia&apos;s leading brand of fresh authentic food</h1>
            <p className='text-small'>Evergreen&apos;s Asian food products are the most trusted and delicious on the market.</p>
            <p className='text-small'>We&apos;re always open to connecting with anyone who wants to source our products. Get in touch with us today.</p>

            <div className='button-group'>
              <a className='button button--primary' href='#order'>Place order</a>
              <a className='button' href='mailto:sales@unigreenfood.com.au' target='_blank' rel='noreferrer'>Special order</a>
            </div>
          </div>

          <div className={`${styles['header__image']} halfWidth display--flex`}>
            <img src={cookingImg} alt='Wholesalers' className='fullWidth' />
          </div>
        </div>
      </Section>

      <Section id='order' className={styles['wholesalers']}>
        <h1 className={styles['wholesalers__heading']}>Wholesalers</h1>
        <WholesalerForm />
      </Section>

      <Footer />
    </>
  );
};

export default Wholesalers;
