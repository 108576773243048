import React from 'react';

import verticalLineImg from 'assets/images/vertical-line-01.svg';

import styles from './StoreLocator.module.scss';
import { Link } from 'react-router-dom';

interface IStoreLocatorProps {
  style?: any;
}

const StoreLocator: React.FC<IStoreLocatorProps> = ({ style }) => {
  return (
    <div id='store-locator' className='store-locator' style={style}>
      <div className='col--auto'>
        <h2 className={styles['store-locator__heading']}>Where can you buy Evergreen products?</h2>
        <p className='margin--none'>Evergreen products are available all over Australia. Use the store locator to find your nearest stockist.</p>
      </div>

      <img src={verticalLineImg} className={styles['store-locator__col-divider']} />

      <div className='col--auto text--center'>
        <h3 className={styles['store-locator__subheading']}>Store locator</h3>
        <p className='margin--none'>Enter your postcode or suburb to find your nearest store.</p>

        <Link to='/contact' className={`button button--primary ${styles['store-locator__link']}`}>
          Find a store
        </Link>
      </div>
    </div>
  );
};

export default StoreLocator;