import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import GA4React from 'ga-4-react';

import { ContentContextProvider } from 'contexts/ContentContext';

import {
  Home,
  Recipes,
  RecipeDetails,
  Products,
  ProductDetails,
  About,
  Wholesalers,
  Contact,
  Privacy
} from 'pages';

const App: React.FC = () => {
  useEffect(() => {
    try {
      // Initialize Google Analytics
      const ga4react = new GA4React(process.env.REACT_APP_ANALYTICS_ID || '');
      ga4react.initialize();
      console.log('Init GA4');
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.error('Google Analytics Failure', error);
    }
  }, []);

  return (
    <ContentContextProvider>
      <Router>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/recipes' element={<Recipes />} />
          <Route path='/recipes/:recipeId' element={<RecipeDetails />} />
          <Route path='/products' element={<Products />} />
          <Route path='/products/:productId' element={<ProductDetails />} />
          <Route path='/about' element={<About />} />
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/wholesales' element={<Wholesalers />} />
          <Route path='/contact' element={<Contact />} />

          {/* TODO: 404 page */}
        </Routes>
      </Router>
    </ContentContextProvider>
  );
};

export default App;
