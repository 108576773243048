import { Directus } from '@directus/sdk';

const directus = new Directus(`https://${process.env.REACT_APP_CONTENT_BASE_URL}`);

import { IProductCategory, IRecipe, IProduct, IStore } from 'types/directus';
import * as logger from './loggingService';

/**
 * Retrieve Product Categories from API.
 *
 * @returns {Promise<Array<IProductCategory>>} Returns ProductCategories asynchronously
 */
export async function getProductCategories (): Promise<Array<IProductCategory>> {
  try {
    const productCategories = await directus.items<'evergreen_product_categories', IProductCategory>('evergreen_product_categories').readByQuery();

    logger.log('Directus', 'got response:', productCategories);
    const sortedCategories = (productCategories.data as Array<IProductCategory> || []).sort((a, b) => {
      if (a.name === 'Other') {
        return 1;
      }
      if (b.name === 'Other') {
        return -1;
      }
      if (a.name === 'Noodles') {
        return -1;
      }
      if (b.name === 'Noodles') {
        return 1;
      }
      if (a.name === 'Tofu') {
        return -1;
      }
      if (b.name === 'Tofu') {
        return 1;
      }
      return a.name.localeCompare(b.name);
    });

    return sortedCategories;
  }
  catch (error) {
    logger.error('Directus', 'request failure');
    throw error;
  }
}

/**
 * Retrieve Recipes from API.
 *
 * @returns {Promise<Array<IRecipe>>} Returns Recipes asynchronously
 */
export async function getRecipes (): Promise<Array<IRecipe>> {
  try {
    const recipes = await directus.items<'evergreen_recipes', IRecipe>('evergreen_recipes').readByQuery({ fields: ['*', 'category.*', 'recipe_ingredients.*'], limit: 200 });

    return recipes.data as Array<IRecipe> || [];
  }
  catch (error) {
    logger.error('Directus', 'request failure');
    throw error;
  }
}

/**
 * Retrieve Products from API.
 *
 * @returns {Promise<Array<IProduct>>} Returns Products asynchronously
 */
export async function getProducts (): Promise<Array<IProduct>> {
  try {
    const recipes = await directus.items<'evergreen_products', IProduct>('evergreen_products').readByQuery({ fields: ['*', 'category.*', 'feature_recipe.*'] });

    logger.log('Directus', 'got response:', recipes);

    return recipes.data as Array<IProduct> || [];
  }
  catch (error) {
    logger.error('Directus', 'request failure');
    throw error;
  }
}

/**
 * Retrieve Stores from API.
 *
 * @returns {Promise<Array<IStore>>} Returns Stores asynchronously
 */
export async function getStores (): Promise<Array<IStore>> {
  try {
    const stores = await directus.items<'evergreen_stores', IStore>('evergreen_stores').readByQuery();

    logger.log('Directus', 'got response:', stores);

    return stores.data as Array<IStore> || [];
  }
  catch (error) {
    logger.error('Directus', 'request failure');
    throw error;
  }
}