import React, { useContext, useState } from 'react';
import { Formik, Field, Form, FormikProps, FieldArray } from 'formik';
import * as yup from 'yup';
import axios from 'axios';

import { Spinner } from 'components';

import { ContentContext } from 'contexts/ContentContext';

import crossImg from 'assets/images/icons/cross.svg';
import styles from './WholesalerForm.module.scss';

interface IProductQuote {
  productName: string;
  quantity?: number;
}

interface ICompanyDetails {
  name: string;
  address: string;
  phone: string;
  abn: string;
  contactName: string;
  contactEmail: string;
}

interface IWholesalerFormData {
  items: Array<IProductQuote>;
  companyDetails: ICompanyDetails;
}

const WholesalerForm: React.FC = () => {
  const { products } = useContext(ContentContext);
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);

  const initialValues: IWholesalerFormData = {
    items: [{
      productName: ''
    }],
    companyDetails: {
      name: '',
      address: '',
      phone: '',
      abn: '',
      contactName: '',
      contactEmail: ''
    }
  };

  const onSubmit = async (values: IWholesalerFormData) => {
    try {
      await axios({
        method: 'post',
        url: '/contact.php',
        headers: { 'content-type': 'application/json' },
        data: values
      });
      setHasSubmitted(true);
    }
    catch (error) {
      console.error(error);
      setHasSubmitted(false);
    }
  };

  const validationSchema = yup.object().shape({
    items: yup.array()
      .of(
        yup.object().shape({
          productName: yup.string()
            .required('Required'),
          quantity: yup.number()
            .required('Required')
        })
      )
      .required('Required')
      .min(1, 'Too Short!'),
    companyDetails: yup.object().shape({
      name: yup.string()
        .min(2, 'Too Short!')
        .max(255, 'Too Long!')
        .required('Required'),
      address: yup.string()
        .min(2, 'Too Short!')
        .max(255, 'Too Long!')
        .required('Required'),
      phone: yup.string()
        .min(2, 'Too Short!')
        .max(255, 'Too Long!')
        .required('Required'),
      abn: yup.string()
        .min(2, 'Too Short!')
        .max(255, 'Too Long!'),
      contactName: yup.string()
        .min(2, 'Too Short!')
        .max(255, 'Too Long!')
        .required('Required'),
      contactEmail: yup.string()
        .min(2, 'Too Short!')
        .max(255, 'Too Long!')
        .required('Required')
    })
  });

  if (hasSubmitted) {
    return (
      <p>Thank you for your order! We&apos;ll be in touch soon.</p>
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}>
      {({ values, errors, touched, isSubmitting }: FormikProps<IWholesalerFormData>) => (
        <Form>
          <h2 className={styles['wholesaler__heading']}>Request wholesalers quote</h2>

          <FieldArray name='items'>
            {({ remove, push }) => (
              <>
                <ul className={styles['products-list']}>
                  {values?.items?.map((item, index) => (
                    <li key={index} className={styles['products-list__item']}>
                      <div className={styles['products-list__number']}>{index + 1}</div>

                      <Field
                        as='select'
                        className={`${styles['products-list__name']} ${touched?.items && errors?.items && errors?.items[index] ? 'error' : ''}`}
                        name={`items[${index}].productName`}>
                        <option value=''>Select a product...</option>
                        {products?.map(product => (
                          <option key={product.id} value={product.name}>{product.name}</option>
                        ))}
                      </Field>

                      <Field
                        className={`${styles['products-list__quantity']} ${touched?.items && errors?.items && errors?.items[index] ? 'error' : ''}`}
                        name={`items[${index}].quantity`}
                        type='number'
                        placeholder='Quantity' />

                      <button
                        type='button'
                        onClick={() => remove(index)}
                        className={styles['products-list__delete']}>
                        <img src={crossImg} alt='Delete' />
                      </button>
                    </li>
                  ))}
                </ul>

                {touched?.items && errors?.items && !Array.isArray(errors?.items) &&
                  <p className='text--center'>Add an item to get started.</p>
                }

                <div className='display--flex'>
                  <button
                    type='button'
                    onClick={() => push({
                      productId: ''
                    })}
                    className={styles['products-list__add']}>
                    Add another product
                  </button>
                </div>
              </>
            )}
          </FieldArray>

          <h2 className={styles['wholesaler__heading']}>Company details</h2>

          <div className={styles['company-details']}>
            <Field
              name='companyDetails.name'
              placeholder='Company name'
              className={`${styles['company-details__field']} ${touched?.companyDetails?.name && errors?.companyDetails?.name ? 'error' : ''}`} />

            <Field
              name='companyDetails.address'
              placeholder='Company address'
              className={`${styles['company-details__field']} ${touched?.companyDetails?.address && errors?.companyDetails?.address ? 'error' : ''}`} />

            <Field
              name='companyDetails.phone'
              placeholder='Company phone'
              className={`${styles['company-details__field']} ${touched?.companyDetails?.phone && errors?.companyDetails?.phone ? 'error' : ''}`} />

            <Field
              name='companyDetails.abn'
              placeholder='Company ABN'
              className={`${styles['company-details__field']} ${touched?.companyDetails?.abn && errors?.companyDetails?.abn ? 'error' : ''}`} />

            <Field
              name='companyDetails.contactName'
              placeholder='Contact name'
              className={`${styles['company-details__field']} ${touched?.companyDetails?.contactName && errors?.companyDetails?.contactName ? 'error' : ''}`} />

            <Field
              name='companyDetails.contactEmail'
              placeholder='Contact email'
              type='email'
              className={`${styles['company-details__field']} ${touched?.companyDetails?.contactEmail && errors?.companyDetails?.contactEmail ? 'error' : ''}`} />
          </div>

          {isSubmitting
            ? <Spinner />
            :
            <button
              type='submit'
              disabled={isSubmitting}
              className={`button--primary ${styles['wholesaler__submit']}`}>
              Request order
            </button>
          }
        </Form>
      )}
    </Formik>
  );
};

export default WholesalerForm;
