import React from 'react';

import { NavigationBar, Section, Footer } from 'components';

import arrowRightImg from 'assets/images/icons/arrow-right.svg';
import arrowLeftImg from 'assets/images/icons/arrow-left.svg';
import cooking1Img from 'assets/images/cooking-07.png';
import cooking2Img from 'assets/images/cooking-09.png';
import noodlesImg from 'assets/images/cooking-08.png';
import aboutImg from 'assets/images/about.jpg';
import cert1Img from 'assets/images/cert-01.png';
import cert2Img from 'assets/images/cert-02.png';
import cert3Img from 'assets/images/cert-03.png';
import cert4Img from 'assets/images/cert-04.png';

import styles from './About.module.scss';

const About: React.FC = () => {
  return (
    <>
      <NavigationBar />

      <Section className='content-bg content-bg--top text--center'>
        <h1 className='header-small'>Our mission</h1>
        <p className='text-large'>With nearly 30 years&apos; experience, Evergreen Foods strives to continue creating the highest-quality, healthiest and most delicious food, as Australia&apos;s leading brand of fresh tofu, soy, noodle and dumpling products.</p>

        <a className='button button--primary' href='/wholesales'>Become a wholesaler</a>
      </Section>

      <Section containerClass={styles['card-section']}>
        <div className={`${styles['card']} ${styles['card--large']}`}>
          <img className={styles['card__feature']} src={cooking1Img} alt='Cooking' />
          <div className={styles['card__content']}>
            <h2 className={styles['card__header']}>Australia&apos;s largest range of fresh tofu, soy, noodle and dumplings products, found in major retailers across the country.</h2>
            <a href='/products'>See products <img src={arrowRightImg} /></a>
          </div>
        </div>

        <div className={styles['card-group']}>
          <div className={styles['card']}>
            <div className={styles['card__content']}>
              <h2 className={styles['card__header']}>Family-run, Australian-owned business operating for nearly 30 years.</h2>
              <a href='/about'>Learn more <img src={arrowRightImg} /></a>
            </div>
          </div>

          <div className={`${styles['card']} ${styles['card--dark-green']}`}>
            <div className={styles['card__content']}>
              <h2 className={styles['card__header']}>World-class research and development team to constantly improve and develop products.</h2>
              <a href='/about'>Learn more <img src={arrowLeftImg} style={{transform: 'scaleX(-1)'}} /></a>
            </div>
          </div>

          <div className={`${styles['card']} ${styles['card--green']}`}>
            <div className={styles['card__content']}>
              <h2 className={styles['card__header']}>Commitment to helping people achieve a healthy lifestyle through our products, which have no preservatives.</h2>
              <a href='/recipes'>Learn more <img src={arrowLeftImg} style={{transform: 'scaleX(-1)'}} /></a>
            </div>
          </div>
        </div>
      </Section>

      <Section className={styles['about']}>
        <div className='halfWidth' style={{marginLeft: 'auto'}}>
          <h2>About Evergreen Foods</h2>
          <p>Evergreen Foods is an Australian brand born over 30 years ago, manufactured under the Unigreen Food Pty Ltd umbrella.</p>
          <p>Evergreen Foods uses industry-leading research and development to implement the highest quality control to continue improving our product standards. It&apos;s what separates our products from the rest of the pack and to achieve a stronger soy taste.</p>
          <p>We&apos;re proud to draw inspiration from our own Chinese heritage (the birthplace of tofu), combining that with our engineering expertise to translate to the most delicious experience on your plate.</p>

          <div className='button-group'>
            <a className='button button--primary' href='/products'>View our product range</a>
          </div>
        </div>

        <img className={styles['about__image']} src={aboutImg} alt='About Evergreen' />
      </Section>

      <Section className={styles['health']}>
        <div className='halfWidth'>
          <h2 className={styles['health__heading']}>Health and long life</h2>
          <p>Tofu and soy products are renowned for their many benefits for health and wellbeing. Evergreen&apos;s range of tofu products (including organic tofu) provide an alternative to protein that are fat-free, high in antioxidants and other micro-nutrients. Free of preservatives, Evergreen&apos;s tofu products last longer and stay fresher.</p>
        </div>

        <img className={styles['health__image']} src={noodlesImg} alt='Health' />
      </Section>

      <Section className={styles['certifications']} containerStyle={{position: 'relative', boxSizing: 'border-box'}}>
        <div className={styles['callout']}>
          <img src={cooking2Img} alt='Cooking' />
          <div className={styles['callout__content']}>
            <h2>Evergreen Foods is always exploring ways to innovate with our products</h2>
            <p>Our family has spent decades researching and developing its own manufacturing machines to make Evergreen Foods products, drawing engineering expertise from Asia including from China, Japan and Singapore. Our manufacturing facility is state-of-the-art, with all employees fully trained and highly skilled in making our product, working to Australian food safety standards.</p>
          </div>
        </div>

        <h2>Quality and certifications</h2>
        <ul className={styles['certifications__list']}>
          <li>
            <div className={styles['certifications__img']}>
              <img src={cert1Img} alt='SQF Level 3' />
            </div>
            SQL Level 3
          </li>
          <li>
            <div className={styles['certifications__img']}>
              <img src={cert2Img} alt='SQF 2000 HACCP' />
            </div>
            SQF 2000 HACCP
          </li>
          <li>
            <div className={styles['certifications__img']}>
              <img src={cert3Img} alt='Halal Certification' />
            </div>
            Halal Certification
          </li>
          <li>
            <div className={styles['certifications__img']}>
              <img src={cert4Img} alt='Kosher Certification' />
            </div>
            Kosher Certification
          </li>
        </ul>
      </Section>

      <Footer />
    </>
  );
};

export default About;
