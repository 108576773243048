import React, { useEffect, useState, useContext, useCallback, useMemo } from 'react';
import Geocode from 'react-geocode';
import { orderByDistance } from 'geolib';
import { GeolibInputCoordinates } from 'geolib/es/types';
import { GoogleMap, Marker, useJsApiLoader, MarkerClusterer } from '@react-google-maps/api';

import { Spinner } from 'components';

import { ContentContext } from 'contexts/ContentContext';
import { IStore } from 'types/directus';

import arrowRightImg from 'assets/images/icons/arrow-right.svg';
import pinImg from 'assets/images/pin.png';
import pinLargeImg from 'assets/images/pin-large.png';

import styles from './StoreLocatorMap.module.scss';

const StoreLocatorMap: React.FC = () => {
  const { stores } = useContext(ContentContext);

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [zipCode, setZipCode] = useState<string | null>(null);
  const [matchingStores, setMatchingStores] = useState<Array<IStore> | null>(null);
  const [displayedZipCode, setDisplayedZipCode] = useState<string | null>(null);
  const [isError, setIsError] = useState<boolean>(false);

  // Initialize Google Map
  const { isLoaded: isMapLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || ''
  });

  // Init position needs to be memorised to avoid rerenders
  const initPosition = useMemo(() => ({
    lat: -27.5084701,
    lng: 121.93303
  }), []);

  // Initialize Google Geocoding API
  useEffect(() => {
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY || '');
    Geocode.setRegion('au');
  }, []);

  // Called first to trigger Google API
  const searchStores: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (!zipCode) {
      return;
    }

    setIsError(false);

    // Retrieve the coordinates of the users zip code
    Geocode.fromAddress(`${zipCode} Australia`).then(
      (response) => {
        // Determine coordinates of users zip code
        const { lat, lng } = response.results[0].geometry.location;

        // Filter out any stores without lat and long
        const filteredStores = stores?.filter(store => store.latitude && store.longitude);

        if (!filteredStores || filteredStores.length === 0) {
          throw new Error('Stores not found');
        }

        // Sort stores by their location
        const orderedStores = orderByDistance({ latitude: lat, longitude: lng}, filteredStores as GeolibInputCoordinates[]);

        // Take closes stores
        const topStores = orderedStores.slice(0, 4);

        // Override the stores with the sorted list
        setMatchingStores(orderedStores as IStore[]);

        // Update map position
        setBounds(topStores as IStore[]);
        setDisplayedZipCode(zipCode);
      },
      (error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        setIsError(true);
      }
    );
  };

  /**
   * Handle google maps load.
   */
  const onLoad = useCallback((map: google.maps.Map) => {
    setMap(map);
  }, []);

  /**
   * Handle google maps unload.
   */
  const onUnload = useCallback(() => {
    setMap(null);
  }, []);

  /**
   * Move to a particular position on the map.
   *
   * @param latitude Latitude to move to.
   * @param longitude Longitude to move to.
   */
  const setPosition = (latitude: number, longitude: number) => {
    // Update map position
    if (map) {
      map.setCenter({ lat: latitude, lng: longitude });
      map.setZoom(13);
    }
  };

  /**
   * Display an area of the map.
   * @param stores List of stores to display.
   */
  const setBounds = (stores: IStore[]) => {
    // Update map position
    if (!map || !stores) {
      return;
    }

    const bounds = new google.maps.LatLngBounds();

    // Add stores to map bounds
    stores.forEach(store => {
      bounds.extend({
        lat: parseFloat(store.latitude),
        lng: parseFloat(store.longitude)
      });
    });

    // Center map on filtered stores
    map.fitBounds(bounds);
  };

  /**
   * Update zip code but don't allow non number characters.
   */
  const handleZipChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setZipCode(e.target.value.replace(/[^0-9]/g, ''));
  };

  /**
   * Reset search and results.
   */
  const resetSearch = () => {
    setMatchingStores(null);
    setZipCode(null);
    setDisplayedZipCode(null);

    if (stores) {
      setBounds(stores);
    }
  };

  if (!isMapLoaded) {
    return <Spinner />;
  }

  return (
    <div className={styles['store-locator']}>
      <form className={styles['store-locator-form']} onSubmit={searchStores}>
        <div className={styles['store-locator-form__header']}>
          <div className={styles['store-locator-form__input-group']}>
            <input
              className={styles['store-locator-form__input']}
              placeholder='Enter zip code to find store'
              value={zipCode || ''}
              onChange={handleZipChange} />

            <button
              className={styles['store-locator-form__button']}
              type='submit'>
              <img src={arrowRightImg} />
            </button>
          </div>

          <div className={styles['store-locator__sub-title']}>
            {displayedZipCode
              ? <>Showing stores near {displayedZipCode}. <button className={styles['store-locator-form__button-small']} onClick={() => resetSearch()}>Show all stores</button></>
              : <>Showing all stores.</>
            }
          </div>
        </div>


        {(matchingStores || stores) &&
          <ul className={styles['store-locator__results']}>
            {(matchingStores || stores)?.map(store => (
              <li key={store.id}>
                <div style={{ width: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                  <p className={styles['store-locator-results__name']}>{store.name}</p>
                  <button
                    type='button'
                    onClick={() => setPosition(parseFloat(store.latitude), parseFloat(store.longitude))}>
                    Get directions
                  </button>
                </div>
                <p>{store.store_address}</p>
              </li>
            ))}
          </ul>
        }

        {isError &&
          <p className={styles['store-locator__error']}>No nearby stores found.</p>
        }
      </form>

      <GoogleMap
        mapContainerClassName={styles['store-locator__map']}
        onLoad={onLoad}
        onUnmount={onUnload}
        center={initPosition}
        zoom={3.6}
        options={{
          fullscreenControl: false,
          mapTypeControl: false
        }}>
        <MarkerClusterer
          styles={[
            {
              url: pinLargeImg,
              width: 40,
              height: 40
            }
          ]}>
          {clusterer => (
            <>
              {stores?.map((store) => (
                <Marker
                  key={store.id}
                  icon={pinImg}
                  position={{
                    lat: parseFloat(store.latitude),
                    lng: parseFloat(store.longitude)
                  }}
                  clusterer={clusterer} />
              ))}
            </>
          )}
        </MarkerClusterer>
      </GoogleMap>
    </div>
  );
};

export default StoreLocatorMap;
