import React from 'react';

import styles from './Section.module.scss';

interface ISectionProps {
  id?: string;
  className?: string;
  style?: any;
  containerClass?: string;
  containerStyle?: any;
}

const Section: React.FC<ISectionProps> = ({ children, containerClass, containerStyle, ...props }) => {
  return (
    <section {...props}>
      <div className={`${styles['section__content']} ${containerClass || ''}`} style={containerStyle}>
        {children}
      </div>
    </section>
  );
};

export default Section;
